// Copyright © 2023 3D2cut SA, All Rights Reserved.

//   ______   _______    ______                         __             ______    ______
//  /      \ /       \  /      \                       /  |           /      \  /      \
// /$$$$$$  |$$$$$$$  |/$$$$$$  |  _______  __    __  _$$ |_         /$$$$$$  |/$$$$$$  |
// $$ ___$$ |$$ |  $$ |$$____$$ | /       |/  |  /  |/ $$   |        $$ \__$$/ $$ |__$$ |
//   /   $$< $$ |  $$ | /    $$/ /$$$$$$$/ $$ |  $$ |$$$$$$/         $$      \ $$    $$ |
//  _$$$$$  |$$ |  $$ |/$$$$$$/  $$ |      $$ |  $$ |  $$ | __        $$$$$$  |$$$$$$$$ |
// /  \__$$ |$$ |__$$ |$$ |_____ $$ \_____ $$ \__$$ |  $$ |/  |      /  \__$$ |$$ |  $$ |
// $$    $$/ $$    $$/ $$       |$$       |$$    $$/   $$  $$/       $$    $$/ $$ |  $$ |
//  $$$$$$/  $$$$$$$/  $$$$$$$$/  $$$$$$$/  $$$$$$/     $$$$/         $$$$$$/  $$/   $$/

/**
 * Burger menu for mobile
 */

const burgerMenu = document.getElementById('burgerMenu');
const menuItems = document.querySelectorAll('.header__menu > li');
const menuButton = document.getElementById('burgerMenuButton');
const body = document.body;

burgerMenu.addEventListener("click", function () {
  body.classList.toggle("open");
  this.classList.toggle("active");
})


for (let menuItem of menuItems) {
  menuItem.addEventListener("click",function () {
    if (body.classList.contains("open")) {
      body.classList.remove("open")
      burgerMenu.classList.remove("active");
    }
  })
}

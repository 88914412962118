// Copyright © 2023 3D2cut SA, All Rights Reserved.

//   ______   _______    ______                         __             ______    ______
//  /      \ /       \  /      \                       /  |           /      \  /      \
// /$$$$$$  |$$$$$$$  |/$$$$$$  |  _______  __    __  _$$ |_         /$$$$$$  |/$$$$$$  |
// $$ ___$$ |$$ |  $$ |$$____$$ | /       |/  |  /  |/ $$   |        $$ \__$$/ $$ |__$$ |
//   /   $$< $$ |  $$ | /    $$/ /$$$$$$$/ $$ |  $$ |$$$$$$/         $$      \ $$    $$ |
//  _$$$$$  |$$ |  $$ |/$$$$$$/  $$ |      $$ |  $$ |  $$ | __        $$$$$$  |$$$$$$$$ |
// /  \__$$ |$$ |__$$ |$$ |_____ $$ \_____ $$ \__$$ |  $$ |/  |      /  \__$$ |$$ |  $$ |
// $$    $$/ $$    $$/ $$       |$$       |$$    $$/   $$  $$/       $$    $$/ $$ |  $$ |
//  $$$$$$/  $$$$$$$/  $$$$$$$$/  $$$$$$$/  $$$$$$/     $$$$/         $$$$$$/  $$/   $$/

// Avoid `console` errors in browsers that lack a console.
(function() {
  var method;
  var noop = function () {};
  var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());

// Place any jQuery/helper plugins in here.
